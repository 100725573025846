import React, { Component } from 'react';
import './home.css';
import appstoreBadge from './img/btn-apple-download.svg';
import xylophoneSmall from './img/Xylophone_small.png';
import ipadScreenShot from './img/iPadScreenshot.png';


class Home extends Component {

  render() {

    return (
      <div className="container mt-3">
        <div className="row">
            <div className="card card-body">
                <div className="row">            
                    <div className="col-lg-6 mb-3 pt-3">
                            <h1 className="d-none d-sm-block h1-title"><img src={xylophoneSmall} className="rounded" alt="Xylophone"/>&nbsp;&nbsp;<span className="h-red">Xylophone</span></h1>
                            <h1 className="d-sm-none mobile h1-title"><img src={xylophoneSmall} className="rounded pt-4" alt="Xylophone"/><br/><br/><span className="h-red">Xylophone</span></h1>
                            <h3 className="h3-title pt-4 pb-3">Toddler-friendly toy app that will introduce your child to the world of music.</h3>

                            <a href="http://appstore.com/igrecwayxylophone" className="text-center pb-4">
                                <img src={appstoreBadge} alt="Download"/>
                            </a>
                    </div>
                    <div className="col-lg-6 pt-3 pb-3">
                        <img src={ipadScreenShot} className="rounded img-fluid" alt="Igrecway Xylophone Screenshot"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
        <div className="card card-body mt-3">
            <div className="row">
                <div className="text-left m-3">

                    <h2 className="h-green d-none d-sm-block h2-title">First step in your child's musical education</h2>
                    <h2 className="h-green d-sm-none mobile h2-title">Music</h2>
                    <p className="lead">

                        If you're looking for a fun music app for toddlers and youngsters, check out the Igrecway Xylophone app, now available for iPad, iPhone and iPod Touch.
                    </p>
                    <br/>
                    <h2 className="h-plum d-none d-sm-block h2-title pt-3">Designed for toddlers</h2>
                    <h2 className="h-plum d-sm-none mobile h2-title pt-3">Designed for toddlers</h2>
                    <p className="lead">
                    A toy app designed for kids between the ages of 2 and 5 years, Igrecway Xylophone is a fun app for kids who are music-oriented. This xylophone app is an ideal first step in your child's musical education.
                    </p>
                    <h2 className="h-red d-none d-sm-block h2-title pt-3">Three sound themes</h2>
                    <h2 className="h-red d-sm-none mobile h2-title pt-3">Three sound themes</h2>
                        <p className="lead">
                        <span className="badge badge-success mr-3">1</span> Traditional xylophone sound
                        <br/><br/>
                        <span className="badge badge-warning mr-3">2</span> Bells
                        <br/><br/>
                        <span className="badge badge-info mr-3">3</span> Barking dog!<br/><br/>
                        </p>
                    <h2 className="h-blue d-none d-sm-block h2-title pt-3">Keeps your child occupied</h2>
                    <h2 className="h-blue d-sm-none mobile h2-title pt-3">Keeps your child occupied</h2>
                    <p className="lead">
                        Ideal app for keeping your child occupied while traveling, shopping or in a waiting room.
                    </p>

                    <h2 className="h-orange d-none d-sm-block h2-title pt-3">Bright colors</h2>
                    <h2 className="h-orange d-sm-none mobile h2-title pt-3">Bright colors</h2>
                    <p className="lead">
                    Igrecway Xylophone is designed for young children, so it features bright colors, large xylophone keys that are easy for little fingers to play, and even an animated background with clouds and floating balloons.
                    </p>

                    <p className="lead pt-3">
                    If you're seeking a toddler-friendly toy app that will introduce your child to the world of music, Igrecway Xylophone is a must-have!
                    </p>
                </div>
            </div>
        </div>
        </div>
   </div>
    );
  }
}

export default Home;
