import React from 'react'
import { Switch, Route  } from 'react-router-dom'
import PrivacyPolicy from '../privacy-policy/privacy-policy';
import Home from '../home/Home.js';
import Press from '../press/press.js';

const Main = () => (
  <main>
    <Switch>
      <Route exact path={process.env.PUBLIC_URL + '/'} component={Home}/>
      <Route path={process.env.PUBLIC_URL + '/index.html'} component={Home}/>
      <Route path={process.env.PUBLIC_URL + '/privacypolicy.html'} component={PrivacyPolicy}/>
      <Route path={process.env.PUBLIC_URL + '/privacypolicy'} component={PrivacyPolicy}/>
      <Route path={process.env.PUBLIC_URL + '/press'} component={Press} />
      <Route path={process.env.PUBLIC_URL + '/press.html'} component={Press} />
      <Route component={Home}/>
    </Switch>
  </main>
)

export default Main