import React from 'react';
import { Link } from 'react-router-dom';


const Press = () => (
    <div className="container mt-3">
       <div className="row">
            <div className="card text-left description p-4">
                <div className="card-body">

                    <Link to="/" className="btn btn-primary btn-sm active">&larr; Home</Link>
                    <h2 className="h-red h2-title">Press Release</h2>
                    11/06/2013
                    <h3 className="h-blue h3-title">Igrecway releases Igrecway Xylophone iPhone/iPad App for Toddlers on App Store</h3>
                    <p><i>The fun kids app for iPhone and iPad is ideal for young music lovers</i></p>

                    <p>Auckland, New Zealand – Igrecway (http://www.igrecway.com) has released its new Igrecway Xylophone app,
                    designed for musically-inclined youngsters who need a bit of entertainment at home or on the road.
                    The kids app is now available for both iPad and iPhone.</p>

                    <p>“Cultivating interests early in life is really essential for parents who wish to see their child
                        excel in areas that interest them. This app is ideal for children who have shown an interest in
                        making music,” explained Evgeny Smirnov,  co-founder of Igrecway.</p>

                    <p>Nataliya Smirnova added, “This children's app is very easy to use. We've designed it specifically
                        with young children and little hands in mind. It's also a fun way to promote the development of
                        those fine motor skills, so it's not just entertaining, it's a developmental toy too!”</p>

                    <p>The Igrecway Xylophone app is designed for toddlers and young children between the ages of 2 to 5.
                        It's suitable for an array of settings, from the home to on-the-go settings such as in the car,
                        during a shopping trip or while hanging out in the waiting room at the doctor's office.</p>

                    <p>The Igrecway Xylophone app comes with three different sound settings for a fun and engaging
                        experience for little aspiring musicians. The sound options include a traditional xylophone sound,
                        along with bells and even a fun barking dog sound.</p>

                    <p>This app features  xylophone features bright colors, large keys and a fun background that features
                        floating clouds and balloons.</p>

                    <p>If you're seeking a toddler-friendly toy app that will introduce your child to the world of music,
                        Igrecway Xylophone is a must-have.</p>

                    <p>In addition to the new kids Xylophone app, Igrecway has developed an array of additional apps,
                        including Igrecway Hangman and the Comoney app.</p>

                    <p>The Igrecway Xylophone app is currently available for iPhone and iPad. It's currently available for
                        purchase for $.99 on the App Store.</p>

                    <p>To learn more about the Igrecway Xylophone app, visit the official app website at
                        http://xylophone.igrecway.com.</p>
                    <br/><br/>
                    <p>App Store Link: <a href="http://itunes.apple.com/app/igrecway-xylophone/id608259356">http://itunes.apple.com/app/igrecway-xylophone/id608259356</a></p>
                    <p>Contact Info: Nataliya Smirnova, <a mailto="igrecway@gmail.com">igrecway@gmail.com</a></p>
                    <p>Igrecway Website: <a href="http://www.igrecway.com">http://www.igrecway.com</a></p>
                    <p>Igrecway Twitter: <a href="https://twitter.com/igrecway">https://twitter.com/igrecway</a></p>
                    <br/><br/>
                    <a className="btn btn-primary" href="IgrecwayXylophone_PressRelease.pdf"><i className="icon-arrow-down"></i>Download press release (pdf)</a>
                </div>
            </div>
        </div>
  </div>		
)

export default Press