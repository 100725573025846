import React, { Component } from 'react';
import './App.css';
import  Footer  from './footer/footer.js';
import  Header  from './header/header.js';
import  Main  from './main/main.js';

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="d-flex flex-column wrapper">
          <Header />
          <Main />
          <Footer />
        </div>
      </div>
    );
  }
}

export default App;
