import React, { Component } from 'react';
import './footer.css';

class Footer extends Component {
  render() {
    return (
        <footer className="pt-4 pb-4 mt-4 text-center bg-dark text-light font-weight-light" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
                <p>© 2008-2099 Igrecway</p>
                <a href="http://www.igrecway.com">Igrecway</a>&nbsp;|&nbsp;<a href="http://igrecway.blogspot.co.nz/">Blog</a>&nbsp;|&nbsp;<a href="https://twitter.com/igrecway">Twitter</a>
                &nbsp;|&nbsp;<a href="mailto:igrecway@gmail.com">Contact Us</a><br/>
              </div>
          </div>
           <div className="row pt-3">
              <div className="col-md-12 col-12 col-md-offset-5">
          				<div className="fb-like" data-href="https://pairs.igrecway.com" data-layout="button_count" data-action="like" data-show-faces="false" data-share="true"></div>
                <br/>
              </div>
            </div>
          </div>
        
    </footer>
    );
  }
}

export default Footer;
