import React from 'react'

const PrivacyPolicy = () => (
    <div className="container mt-3">
        <div className="row">		
          <div className="col-md-12 col-xs-12 card card-body description text-justify p-4">
            <h2 className="h2-title pb-4 pt-4">Privacy Policy</h2>
            <p>This Privacy Policy describes the information collected by Igrecway through the Igrecway Xylophone Game mobile application.</p>

            <p> We do not ask for or collect personally identifiable information such as names or email addresses about the users of our mobile applications. We do not include any social network integration, or third party advertising.</p>

            <p>If you have any questions about our Privacy Policy please contact us at: <a href="mailto:igrecway@gmail.com">igrecway@gmail.com</a></p>
        </div>
    </div>
  </div>		
)

export default PrivacyPolicy